import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Validator from "validator"
import CloseBtn from "../images/Close_Button.svg"

const ChargeEtcPointModalPopup = props => {
  const { open = false, onClose = () => {}, orderFn = () => {} } = props
  const [amt, setAmt] = useState("")
  useEffect(() => {
    setAmt("")
  }, [open])
  return open == true ? (
    <div className="popup-modal-container" style={{ zIndex: 600 }}>
      <div className="popup-modal-div">
        <div className="popup-modal-close-div">
          <button className="btn" onClick={onClose}>
            <img src={CloseBtn}></img>
          </button>
        </div>
        <p style={{ fontSize: "18px", lineHeight: "24px", fontWeight: 500 }}>충전금액을 직접 입력하세요</p>
        <div style={{ width: "100%", padding: "0px 20px" }} className="d-flex flex-shrink-0 mt-3">
          <input
            value={amt}
            onChange={e => setAmt(e.target.value)}
            placeholder="충전 금액 (5,000원 단위)"
            type="number"
            className="fgb-input"
          ></input>
        </div>
        <div className="popup-modal-btn-div btns mt-4">
          <button onClick={onClose} className="btn btn-primary fgb-btn-gray me-2">
            취소
          </button>
          <button
            onClick={() => {
              if (parseInt(amt) % 5000 == 0 && amt != 0) {
                orderFn(amt, "FEE")
              } else {
                setAmt("")
              }
            }}
            className="btn btn-primary font-white"
          >
            확인
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  )
}

export default ChargeEtcPointModalPopup
