import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Validator from "validator"
import CloseBtn from "../images/Close_Button.svg"

const ChargePointModalPopup = props => {
  const { open = false, onClose = () => {}, setEtc = () => {}, orderFn = () => {} } = props

  return open == true ? (
    <div className="popup-modal-container" style={{ zIndex: 600 }}>
      <div className="popup-modal-div">
        <div className="popup-modal-close-div">
          <button className="btn" onClick={onClose}>
            <img src={CloseBtn}></img>
          </button>
        </div>
        <p style={{ fontSize: "24px", lineHeight: "35px", fontWeight: 500 }}>충전 금액</p>
        <div className="d-flex mt-4">
          <button
            onClick={() => {
              orderFn(5000, "POINT_CHARGING")
            }}
            style={{ width: "90px", height: "40px", fontSize: "13px" }}
            className="btn  fgb-btn-gray fgb-btn-s"
          >
            5,000원
          </button>
          <button
            onClick={() => {
              orderFn(10000, "POINT_CHARGING")
            }}
            style={{ width: "90px", height: "40px", fontSize: "13px" }}
            className="btn fgb-btn-gray fgb-btn-s ms-2"
          >
            10,000원
          </button>
          <button
            onClick={() => {
              orderFn(30000, "POINT_CHARGING")
            }}
            style={{ width: "90px", height: "40px", fontSize: "13px" }}
            className="btn fgb-btn-gray fgb-btn-s ms-2"
          >
            30,000원
          </button>
        </div>
        <div className="d-flex mt-2 mb-4">
          <button
            onClick={() => {
              orderFn(70000, "POINT_CHARGING")
            }}
            style={{ width: "90px", height: "40px", fontSize: "13px" }}
            className="btn  fgb-btn-gray fgb-btn-s"
          >
            70,000원
          </button>
          <button
            onClick={() => {
              orderFn(100000, "POINT_CHARGING")
            }}
            style={{ width: "90px", height: "40px", fontSize: "13px" }}
            className="btn fgb-btn-gray fgb-btn-s ms-2"
          >
            100,000원
          </button>
          <button onClick={setEtc} style={{ width: "90px", height: "40px", fontSize: "13px" }} className="btn btn-primary font-white fgb-btn-s ms-2">
            다른금액
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  )
}

export default ChargePointModalPopup
