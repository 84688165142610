/*
  마이렛미업 렛미업 포인트 페이지
*/
import React, { useCallback, useEffect, useState } from "react"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import LeftSideBar from "../../components/my/LeftSideBar"
import RightSideBar from "../../components/my/RightSideBar"
import MyContainer from "../../containers/MyContainer"
import PaymentsContainer from "../../containers/PaymentsContainer"
import PrivateRoute from "../../components/privateRoute"

import { localStorgeSet, popupFn, rnMessageListener } from "../../utils/util"

import { navigate } from "gatsby-link"
import { loadTossPayments } from "@tosspayments/payment-sdk"
import ChargePointModalPopup from "../../popups/ChargePointModalPopup"
import ChargeEtcPointModalPopup from "../../popups/ChargeEtcPointModalPopup"
import ModalPopup from "../../popups/ModalPopup"
import Validator from "validator"

import SignApis from "../../apis/SignApis"

//렛미업 포인트
const COUNT = 20
const TYPE = {
  CHARGE: "충전",
  SAVE: "적립",
  REWARD: "리워드",
  USE: "사용",
  EXPIRATION: "만료",
}
const Point = props => {
  const {
    postPaymentsOrder,
    myPoint,
    getPointMy,
    getPointLogs,
    getPointLogsAdd,
    myPointLog,
    myPointLogTotal,
  } = props
  const filterArr = ["TODAY", "WEEK", "MONTH", "QUARTER"]
  const filterKindArr = ["", "CHARGE", "SAVE", "REWARD", "USE", "EXPIRATION"]

  const [filterPeriod, setFilterPeriod] = useState(0)
  const [filterKind, setFilterKind] = useState(0)
  const [page, setPage] = useState(0)
  const [openPointPopup, setOpenPointPopup] = useState(false)
  const [openPointEtcPopup, setOpenPointEtcPopup] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const url = process.env.CALLBACK_URL

  //get data
  const getData = async () => {
    let temp = {
      periodType: filterArr[filterPeriod],
      searchType: filterKindArr[filterKind],
      page: 0,
    }
    await getPointMy()

    await getPointLogs(temp)
  }

  useEffect(() => {
    setPage(0)
    getData()
  }, [filterPeriod, filterKind])

  //더보기
  const increasePage = async () => {
    //add pointLogs
    let temp = {
      periodType: filterArr[filterPeriod],
      searchType: filterKindArr[filterKind],
      page: page + 1,
      myPointLog: myPointLog,
    }
    await getPointLogsAdd(temp)
    // totalCount 조건
    if (COUNT * (page + 1) < myPointLogTotal) setPage(prev => prev + 1)
  }

  const messageDataFn = useCallback(e => {
    let data = JSON.parse(e.data)
    if (data.type == "BACK") navigate(-1)
  }, [])

  //포인트 충전
  const orderFn = async (amt, type) => {
    let result = await postPaymentsOrder({
      amount: amt,
      payType: type,
    })
    if (result) {
      setOpenPointPopup(false)
      setOpenPointEtcPopup(false)
      localStorgeSet("paySeq", result.paySeq)
      let temp = {
        amount: result.amount,
        orderId: result.orderId,
        orderName: type,
        customerName: result.name,
        successUrl: `${url}/authOrder/`,
        failUrl: `${url}/authOrder/`,
      }
      const tossPayments = await loadTossPayments(process.env.TOSS_CLIENT_KEY)
      if (tossPayments) {
        tossPayments.requestPayment("카드", temp).catch(function (error) {
          if (error.code === "USER_CANCEL") {
            popupFn("결제가 취소되었습니다.")
            localStorage.removeItem("paySeq")
          }
        })
      }
    }
  }

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", messageDataFn)
    }
    return () => {
      document.removeEventListener("message", messageDataFn)
    }
  }, [])

  return (
    <PrivateRoute>
      <Layout title="내 렛미업 포인트">
        <SEO title="마이렛미업" />
        <ModalPopup
          btnText={"확인"}
          open={openModal}
          onClose={() => setOpenModal(false)}
          contents={
            "포인트 충전 안내\n제휴사 관리 메뉴에서 포인트 충전 후\n사용 가능합니다."
          }
          onClickBtn={() => setOpenModal(false)}
        />
        <ChargePointModalPopup
          orderFn={orderFn}
          setEtc={() => setOpenPointEtcPopup(true)}
          open={openPointPopup}
          onClose={() => setOpenPointPopup(false)}
        />
        <ChargeEtcPointModalPopup
          orderFn={orderFn}
          open={openPointEtcPopup}
          onClose={() => setOpenPointEtcPopup(false)}
        />
        <div className="d-flex justify-content-center ">
          <LeftSideBar />
          <div className="container my-container">
            {/* 타이틀 */}
            <p className="my-point-title">내 렛미업 포인트</p>

            {/* 포인트 요약 */}
            <div className="my-point-summary-view">
              <p>
                사용할 수 있는 렛미업 포인트는{" "}
                <span>
                  {Number(myPoint.canUsePoint || 0).toLocaleString()} LP
                </span>{" "}
                입니다.
              </p>
              <div className="card-view ">
                <div className="card-item">
                  <p className="card-title">충전 포인트</p>
                  <p className="card-content">
                    {Number(myPoint.chargePoint || 0).toLocaleString()} LP
                  </p>
                </div>
                <div className="card-item">
                  <p className="card-title">적립 포인트</p>
                  <p className="card-content">
                    {Number(myPoint.savePoint || 0).toLocaleString()} LP
                  </p>
                </div>
                <div className="card-item">
                  <p className="card-title">보상 포인트</p>
                  <p className="card-content">
                    {Number(myPoint.rewardPoint || 0).toLocaleString()} LP
                  </p>
                </div>
              </div>
              <div className="button-view">
                <button
                  onClick={async () => {
                    let type = await SignApis.postAuthentication()
                    if (type?.data?.loginUserType == "USER") {
                      setOpenPointPopup(true)
                    } else {
                      setOpenModal(true)
                    }
                  }}
                  className="btn btn-primary font-white"
                >
                  포인트 충전하기
                </button>
              </div>
            </div>

            {/* 포인트 내역 */}
            <div className="my-point-history-view">
              {/* filter */}
              <div className="filter-view">
                <select
                  className="form-select"
                  value={filterPeriod}
                  onChange={e => setFilterPeriod(e.target.value)}
                >
                  <option value={0}>오늘</option>
                  <option value={1}>1주일</option>
                  <option value={2}>1개월</option>
                  <option value={3}>3개월</option>
                </select>

                <select
                  className="form-select"
                  value={filterKind}
                  onChange={e => setFilterKind(e.target.value)}
                >
                  <option value={0}>전체</option>
                  <option value={1}>충전</option>
                  <option value={2}>적립</option>
                  <option value={3}>보상</option>
                  <option value={4}>사용</option>
                  <option value={5}>만료</option>
                </select>
              </div>
              {/* list */}
              <div className="list-view-wrapper">
                <p className="total-count">총 {myPointLogTotal}개</p>
                <div className="list-view">
                  {myPointLog
                    .filter((i, idx) => idx < COUNT * (page + 1))
                    .map((i, idx) => (
                      <div className="list-item" key={idx}>
                        <div className="list-item-no-title-content-view">
                          <p className="list-item-no">{idx + 1}</p>
                          <div className="list-item-title-content-view">
                            <div className="list-item-title-view">
                              <p className="list-item-title">
                                {(i.action || "") + " " + i.depth1}
                              </p>
                              <p className="list-item-sub-title">{i.depth2}</p>
                            </div>
                            <p
                              style={{
                                color:
                                  i.type == "USE" || i.type == "EXPIRATION"
                                    ? "#F53C14"
                                    : "#00c3c5",
                              }}
                              className="list-item-content"
                            >
                              {Number(i.point || 0).toLocaleString()} LP{" "}
                              {TYPE[i.type]}
                            </p>
                          </div>
                        </div>

                        <p className="list-item-date">{i.regDatetime}</p>
                      </div>
                    ))}
                </div>
              </div>
              {/* 더보기 */}
              <div
                className={`more-see ${
                  COUNT * (page + 1) < myPointLogTotal ? "active" : ""
                }`}
              >
                <button
                  className="btn btn-primary font-white"
                  onClick={increasePage}
                >
                  더보기
                </button>
              </div>
            </div>
          </div>
          <RightSideBar />
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default PaymentsContainer(MyContainer(Point))
